/**
 * Rotates an element by 180 degrees.
 * @param {String} elementClass The CSS selector of the element.
 */
const rotateElement180 = elementClass => {
  const element = document.querySelector(`.${elementClass}`);
  element.style.transform =
    getComputedStyle(element).transform === 'none'
      ? (element.style.transform = 'rotate(180deg)')
      : (element.style.transform = 'none');
};

/**
 * Creates an element toggle.
 * @param {String} toggleClass The CSS selector of the toggle element.
 * @param {String} targetClass The CSS selector of the target element.
 * @param {String} arrowClass The CSS selector of any arrow that we want to rotate.
 * @param {Array} extraElementsClasses An array containing the CSS selectors of any extra elements that we want to hide/show.
 * @param {Array} extraDropdownClasses An array containing the CSS selectors of any dropdowns that we want to hide.
 */
export const createDropdownToggle = (
  toggleClass,
  targetClass,
  arrowClass,
  extraElementsClasses,
  extraDropdownClasses
) => {
  const toggle = document.querySelector(`.${toggleClass}`);
  const target = document.querySelector(`.${targetClass}`);

  if (toggle && target) {
    toggle.addEventListener('click', () => {
      target.classList.toggle('showing');

      if (arrowClass) rotateElement180(arrowClass)

      if (extraElementsClasses) {
        extraElementsClasses.forEach(elementClass => {
          const element = document.querySelector(`.${elementClass}`);
          element.classList.toggle('showing');
        });
      }

      if (extraDropdownClasses) {
        extraDropdownClasses.forEach(elementClass => {
          const elements = document.querySelectorAll(`.${elementClass}`);
          elements.forEach(element => {
            if (element.classList.contains('showing')) element.classList.remove('showing')
          })
        });
      }
    });
  }
};
